import GroupPlatform from './GroupPlatform';
import { MEMBERS_APP_DEF_ID } from '../app-configs';
import { AppActionEvent, PageActionsEvent } from './types/manifest';

let platform: GroupPlatform;
export const editorReady = async (
  editorSDK,
  appDefinitionId,
  { origin, firstInstall },
) => {
  platform = new GroupPlatform(editorSDK, appDefinitionId);
  try {
    if (firstInstall) {
      await platform.install();
    } else {
      await platform.setGroupPageState(appDefinitionId);
      // For sites where groups members area was installed
      // TODO: 🚨 remove later see https://jira.wixpress.com/browse/GROUP-673
      await platform.deleteGroupsMemberArea();
    }
  } catch (e) {
    console.error('Groups App installation: FAIL');
    await platform.deleteApp();
    throw e;
  }
};
export const onEvent = (event) => {
  switch (event.eventType) {
    case AppActionEvent.ADD_PAGE:
      return platform.addPage();
    case PageActionsEvent.REMOVE:
      return platform.deleteApp();
    default:
      return;
  }
};

export const getAppManifest = async () => {
  return platform.getManifest();
};

export const handleAction = async ({ type, payload }) => {
  console.log('>: handleAction call', type, payload);
  if (type === 'appInstalled') {
    try {
      switch (payload.appDefinitionId) {
        case MEMBERS_APP_DEF_ID:
          await platform.installMembersApps();
          break;
        case platform.appDefId:
          await platform.setGroupPageState(platform.appDefId);
          await platform.installMembers();
          console.log('GROUPS_PLATFORM installed');
          break;
        default:
      }
    } catch (e) {
      console.error('Groups App handle action: FAIL');
      await platform.deleteApp();
      throw e;
    }
  }
};
