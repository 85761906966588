import { PageActionsEvent, PageSettingsTab } from './types/manifest';
import { Translations } from './Translations';

// https://bo.wix.com/wix-docs/client/client-frameworks#editor-platform.articles.pages-manifest
export function getGroupsManifest(
  translation: Translations,
  pageSettingsUrls: {},
) {
  return {
    pages: {
      pageActions: {
        default: [
          {
            type: 'Pages_Actions_Page_Rename',
          },
          {
            title: translation.t('groups-web.page.delete'),
            icon: 'delete_icon',
            event: PageActionsEvent.REMOVE,
            type: 'page_remove',
          },
        ],
        groupPage: [],
      },
      pageSettings: {
        default: [
          {
            title: translation.t('groups-web.page.info'),
            type: PageSettingsTab.PAGE_INFO,
          },
          {
            title: translation.t('groups-web.page.layout'),
            type: PageSettingsTab.LAYOUT,
          },
          {
            title: translation.t('groups-web.page.permissions'),
            type: PageSettingsTab.PERMISSIONS,
          },
          {
            title: translation.t('groups-web.page.seo'),
            type: PageSettingsTab.SEO,
          },
        ],
        groupPage: [
          {
            title: translation.t('groups-web.page.info'),
            type: PageSettingsTab.PAGE_INFO,
            url: pageSettingsUrls[PageSettingsTab.PAGE_INFO],
          },
          {
            title: translation.t('groups-web.page.layout'),
            type: PageSettingsTab.LAYOUT,
          },
          {
            title: translation.t('groups-web.page.permissions'),
            type: PageSettingsTab.PERMISSIONS,
          },
        ],
      },
      applicationSettings: {
        default: {
          displayName: 'Groups Pages',
          helpId: '',
        },
      },
      applicationActions: {
        default: [],
      },
    },
  };
}
